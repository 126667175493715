
import {
    createVNode
} from 'vue';
import {
    ExclamationCircleOutlined,
    DeleteOutlined,
    PlusOutlined
} from '@ant-design/icons-vue';
import Editor from './editor';
import RoleAuth from "./role_auth/index.vue"

export default {
    name: "Role",
    components: {
        DeleteOutlined,
        PlusOutlined,
        Editor,
        RoleAuth
    },
    data() {
        return {
            url: '/admin/role/list/admin',
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            // 是否显示权限弹窗
            showRoleAuth: false
        };
    },
    computed: {
        columns() {
            return [{
                    key: 'index',
                    width: 48,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t('角色名称'),
                    dataIndex: 'name',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('仅限查询个人信息'),
                    dataIndex: 'view_only_self',
                    width: 150,
                    sorter: true,
                    customRender:({text})=>{
                        return text ? "是" :"否"
                    }
                },
                {
                    title: this.$t('备注'),
                    dataIndex: 'remark',
                    width: 400,
                    sorter: true
                },
                {
                    title: this.$t('启用'),
                    dataIndex: 'enabled',
                    width: 100,
                    sorter: true,
                    slots: {
                        customRender: 'enabled'
                    }
                },
                {
                    title: this.$t('创建人'),
                    dataIndex: 'creator',
                    width: 100,
                    sorter: true
                },
                {
                    title: this.$t('创建时间'),
                    dataIndex: 'created',
                    width: 150,
                    sorter: true
                },
                {
                    title: this.$t('操作'),
                    key: 'action',
                    slots: {
                        customRender: 'action'
                    }
                }
            ]
        }
    },
    methods: {
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 新建
         */
        createNew() {
            this.current = null;
            this.showEdit = true;
        },
        /* 打开编辑弹窗 */
        openEdit(row) {
            this.current = row;
            this.showEdit = true;
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading('请求中...', 0);

            this.$http.post('/admin/role/delete', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error('请至少选择一条数据');
            }

            this.$confirm({
                title: this.$t('提示'),
                content: this.$t('确定要删除选中的数据吗?'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('请求中...', 0);

                    this.$http.post('/admin/role/delete', {
                        ids: this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        /* 修改状态 */
        editState(checked, row, field) {
            this.$http.get(`/admin/role/state/${row.id}?${field}=${(checked ? 1 : 2)}`).then(res => {
                if (res.data.code === 0) {
                    row[field] = checked;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
        },
        /* 弹出分配窗口 */
        openRoleAuth(row) {
            this.showRoleAuth = true;
            this.current = row;
        }
    }
}
